import {useEffect} from "react";
import LightBulb from "../../assets/images/442815932.png";
import PageImage from "../../components/PageImage";
import {constant} from "./about.constant";
import { Box, Grid2 } from "@mui/material";

const About = () => {
  const isOdd = (num) => {
    return num % 2 === 0 ? true : false
  }
  useEffect(() => {
    document.title = `${constant.title} | Aurora Rising`;
    document.querySelector("link[rel='canonical']").setAttribute("href", window.location);
  })
  return (
    <>
    <Grid2 container>
      <Grid2 item size={12}>
        <PageImage image={LightBulb} />
      </Grid2>
      <Grid2 item size={12}>
        <h1>About Us</h1>
        {constant.aboutParas.map((para, index) => (
          <p key={index}>{para}</p>
        ))}
      </Grid2>
      <Grid2 item size={12}>
        <Box component={'h3'} sx={{fontSize: '30px'}}>Meet Our Team</Box>
      </Grid2>
      </Grid2>
      <Grid2 container sx={{alignItems: 'center', display: {xs: 'none', sm: 'flex'}}}>
      
        {constant.team.map((member, index) => {
            return(
              <>
                  <Grid2 item size={{xs: 12, sm: 5}}>
                    {isOdd(index) ? <Box component="img" sx={{maxWidth: '100%'}} src={`/images/${member.image}`} alt={member.name} /> : <div><Box component={'h3'} sx={{fontSize: '30px'}}>{member.name} ({member.creds})</Box><p>{member.bio}</p></div> }
                  </Grid2>
                  <Grid2 item size={{xs: 12, sm: 5}} offset={1}>
                  {!isOdd(index) ? <Box component="img" sx={{maxWidth: '100%'}} src={`/images/${member.image}`} alt={member.name} /> : <div><Box component={'h3'} sx={{fontSize: '30px'}}>{member.name} ({member.creds})</Box><p>{member.bio}</p></div> }
                  </Grid2>
                  <Box component="hr" sx={{width: '100%', margin: '40px 0', height: '2px', border: 'none', backgroundColor: "#EEE"}} />
                </>
                
            )
        }
        )}
        </Grid2>
        <Grid2 container sx={{display: {xs: 'flex', sm: 'none'}, justifyContent: 'center'}}>
        {constant.team.map((member, index) => {
            return(
              <>
                  <Grid2 item size={12}>
                    <Box component="img" sx={{maxWidth: '100%', margin: '0 auto', display: 'block'}} src={`/images/${member.image}`} alt={member.name} />
                  </Grid2>
                  <Grid2 item size={12}>
                    <Box component={'h3'} sx={{fontSize: '30px'}}>{member.name} ({member.creds})</Box>
                    <p>{member.bio}</p>
                  </Grid2>
                  <Box component="hr" sx={{width: '100%', margin: '40px 0', height: '2px', border: 'none', backgroundColor: "#EEE"}} />
                </>
                
            )
        }
        )}
        </Grid2>
        </>
  );
};

export default About;
