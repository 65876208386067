export const constant = {
  title: "Home Page",
  introParagraph: `Providing behavioural and psychological and services in the Calgary area since 2024`,
  pointList: [
    `Assessments`,
    `Behaviour Consultation`,
    `Counseling Services`,
    `Professional Supervision`,
    `Psychological Consultation`,
    `Supervision for Students & Professionals`
  ],
  missionStatement:
    "At Aurora Rising Ltd., our mission is to empower individuals of all abilities through personalized psychological and behavioural support. We are committed to providing a safe, inclusive environment that fosters growth, resilience, independence, and well-being. By collaborating with clients, families, and communities, we strive to unlock potential, enhance quality of life, and promote understanding and acceptance. Together, we envision a world where everyone can thrive, celebrating their unique strengths and contributions."
};
