import Banner from "./../../assets/images/725261086.jpeg";
import { Grid2 } from "@mui/material";
import Box from "@mui/material/Box";
import {constant} from "./home.constant";
import { useEffect } from "react";

const HomePage = () => {

  useEffect(() => {
    document.title = `${constant.title} | Aurora Rising`;
    document.querySelector("link[rel='canonical']").setAttribute("href", window.location);
  })
  return (
  <>
    <Grid2 container spacing={2} columns={12}>
      <Grid2 item size={12}>
        <Box component={"h1"} sx={{color: "#553a75", fontSize: '40px', textAlign: 'center'}}>Aurora Rising</Box>
        <h2 className={'centered'}>{constant.introParagraph}</h2>
      </Grid2>
      <Grid2 item size={12} sx={{padding: '15% 0%',backgroundSize: 'cover', backgroundImage: `url("${Banner}")`}}>
        <Grid2 container spacing={2} columns={12}>
          <Grid2 item size={{xs: 12, sm: 6, md: 5}} offset={{xs: 0, sm: 6, md: 7}}>
            <Box component={'div'}>
              <Box component={'ul'} sx={{padding: {xs: '20px 30px', md: '0 30px'}, backgroundColor: {xs: "rgba(255, 255, 255, 0.5)", md: 'transparent'}}}>
                {constant.pointList.map((point, index) => (
                  <Box component={"li"} sx={{fontSize: {xs: '20px', md: '30px'}, textShadow: "0px 0px 5px rgba(255, 255, 255, 1)"}} key={index}>{point}</Box>
                ))}
              </Box>
              </Box>
            </Grid2>
          </Grid2>
      </Grid2>
      <Grid2 item size={12}>
        <Box component={'h3'} sx={{textAlign: 'center', fontSize: '38px'}}>Mission Statement</Box>
        <Box component={'p'} sx={{fontSize: '20px'}}>{constant.missionStatement}</Box>
        </Grid2>
    </Grid2>
    </>
  );
};

export default HomePage;
