import {useEffect} from "react";
import Banner from "../../assets/images/322613090.jpeg";
import { servicesConstants } from "./services.constants";
import { Box, Grid2 } from "@mui/material";

const Services = () => {

  useEffect(() => {
    document.title = `${servicesConstants.title} | Aurora Rising`;
    document.querySelector("link[rel='canonical']").setAttribute("href", window.location);
  })

  return (
    <Grid2 container spacing={2} columns={12} sx={{ position: "relative" }}>
      <Box
        component="div"
        sx={{
          backgroundImage: `url(${Banner})`,
          backgroundSize: "contain",
          position: "fixed",
          xIndex: "-1",
          backgroundRepeat: "no-repeat",
          width: "100%",
          maxWidth: "1200px",
          height: "100%",
          opacity: "0.15"
        }}
      />
      <div className="centered">
        <h1>Services</h1>
      </div>
      <Grid2 container spacing={2} columns={12} sx={{xIndex: 1}}>
      {servicesConstants.service.map(service => {
        return (
          <>
            <Grid2 item size={{xs: 12, sm: 6}} sx={{textAlign: {xs: 'center', sm: 'right'}}}>
              <h3>
                {service.title}
              </h3>
            </Grid2>
            <Grid2 item size={{xs: 12, sm: 5}}>
            {service.content.map(para => {
                  return (
                    <p
                      key={para}
                      style={{ position: "relative", left: "10px" }}
                    >
                      {para}
                    </p>
                  );
                })}
            </Grid2>
          </>
        );
      })}
      </Grid2>
    </Grid2>
  );
};

export default Services;
